import { useUserStore } from 'user-store';
import { useQueryClient } from '@tanstack/react-query';
import login from 'api/users/login';
import logout from 'api/users/logout';
import axios from 'api/axios';
import { clearLocalStorage } from 'util/clear-local-storage';
import getErrorMessage from 'util/handle-catch-error';
import { datadogRum } from '@datadog/browser-rum';

function useProvideAuth() {
	// use local storage hook: check local storage has token, set token, clear token
	const { addUserState, clearUserState, accessToken, isAuthenticated, gitBookToken } = useUserStore();
	const queryClient = useQueryClient();

	axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			if (error?.response?.status === 401) {
				datadogRum.addAction('session-expired');
				clearAuthState();
			}
			return Promise.reject(error);
		}
	);

	// auth related APIs
	const handleLogin = async (payload, errorCallback) => {
		try {
			const result = await login(payload);
			datadogRum.addAction('login-success', { id: result.userInfo.externalId });
			await addUserState(result.accessToken);
		} catch (err: unknown) {
			const message = getErrorMessage(err);
			errorCallback(message);
		}
	};

	const clearCache = () => {
		queryClient.removeQueries();
		clearLocalStorage();
		window.location.reload();
	};

	const clearAuthState = () => {
		clearUserState();
		clearCache();
	};

	const handleLogout = () => {
		try {
			logout();
		} finally {
			datadogRum.addAction('logout');
			clearAuthState();
		}
	};

	// Return the user object and auth methods
	return {
		isAuthenticated,
		accessToken,
		gitBookToken,
		login: handleLogin,
		logout: handleLogout,
		clearCache,
	};
}

export { useProvideAuth };
