import {
	Alert,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Autocomplete,
	TextField,
	CardActions,
	Button,
	Checkbox,
} from '@mui/material';
import { useState } from 'react';
import { useUserStore } from 'user-store';
import changeCurrentUserGroup from 'api/users/changeCurrentUserGroup';
import { UserGroupBaseType } from 'api/api.types';
import { useAuthentication } from 'providers';
import getErrorMessage from 'util/handle-catch-error';

export default function AssumeUserGroup() {
	const { clearCache } = useAuthentication();
	const { user, addUserState } = useUserStore((state) => ({
		user: state.user,
		addUserState: state.addUserState,
	}));
	const currentUserGroup = user?.currentUserGroup || null;
	// confirmation that the user can switch their group
	const [isGroupSwitchable, setIsGroupSwitchable] = useState(false);
	// auto complete
	const [value, setValue] = useState<UserGroupBaseType | null>(currentUserGroup);
	const [inputValue, setInputValue] = useState('');
	const [error, setError] = useState<string | null>(null);

	const handleAssumingContinue = async () => {
		try {
			const res = await changeCurrentUserGroup(user?.id || '', { userGroupId: value?.id || '' });
			await addUserState(res.accessToken);
			clearCache();
		} catch (err: unknown) {
			const message = getErrorMessage(err);
			setError(message);
		}
	};

	return (
		<Card sx={{ width: '100%' }} data-testid="assume-user-group">
			<CardHeader
				title="User Assumption Tool"
				sx={{
					backgroundColor: 'primary.main',
					color: 'primary.contrastText',
				}}
			/>
			<CardContent>
				<Typography sx={{ mb: 2 }} align="center">
					Current User Group: {user?.currentUserGroup?.name}
				</Typography>
				<Typography sx={{ mb: 3 }} align="center">
					Confirm that you are not screen sharing
					<Checkbox checked={isGroupSwitchable} onChange={() => setIsGroupSwitchable(!isGroupSwitchable)} />
				</Typography>
				<Autocomplete
					data-testid="user-group-auto-complete"
					id="user-groups"
					sx={{ width: '400px', m: 'auto' }}
					disablePortal
					disabled={!isGroupSwitchable}
					options={user?.assumableUserGroups || []}
					value={value}
					onChange={(event: any, newValue: UserGroupBaseType | null) => {
						if (newValue !== null) {
							setValue(newValue);
						}
					}}
					inputValue={inputValue}
					getOptionLabel={(option) => option.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					renderInput={(params) => <TextField {...params} label="Click to expand" />}
				/>
			</CardContent>
			<CardActions sx={{ justifyContent: 'center' }}>
				<Button
					disabled={!isGroupSwitchable || !Boolean(value) || value?.id === currentUserGroup?.id}
					onClick={handleAssumingContinue}>
					Switch Group
				</Button>
			</CardActions>
			{error != null && (
				<Alert severity="error" onClose={() => setError(null)}>
					Error: {error}
				</Alert>
			)}
		</Card>
	);
}
