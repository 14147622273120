import {
	Box,
	Paper,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Button,
	TextField,
	FormHelperText,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { PasswordInput } from 'pages/user-account/PasswordInput';
import { useAuthentication } from 'providers/authentication/authentication-hook';
import { useLocation, Navigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { mainProtectedRoute } from 'routes/const';
import { gitBookURL } from 'routes/const';

export default function Login() {
	const auth = useAuthentication();
	const [searchParams] = useSearchParams();
	const isFromGitBook = searchParams.get('location') !== null;
	const { state } = useLocation();
	const [error, setError] = useState('');

	const { handleSubmit, control } = useForm({
		mode: 'onChange',
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const onSubmit = (form) => {
		auth.login(form, (err) => {
			setError(err);
		});
	};

	if (auth.isAuthenticated) {
		if (isFromGitBook) {
			window.location.href = `${gitBookURL}/?jwt_token=${auth.gitBookToken}`;
		} else {
			return <Navigate to={state?.location || mainProtectedRoute} />;
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box sx={{ width: '343px', margin: 'auto' }}>
				<Paper elevation={3}>
					<Card>
						<CardHeader title="Log In" />
						<CardContent>
							<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
								<FormHelperText error>{error || ' '}</FormHelperText>
								<Controller
									name="email"
									control={control}
									rules={{
										required: 'Email is required',
										pattern: {
											value:
												/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
											message: 'Email format is incorrect', // JS only: <p>error message</p> TS only support string
										},
									}}
									render={({ field: { onChange, value, name }, fieldState: { error } }) => (
										<TextField
											id={name}
											label="Email"
											variant="outlined"
											size="medium"
											type="email"
											value={value}
											error={error !== undefined}
											helperText={error?.message || ''}
											onChange={onChange}
											fullWidth
										/>
									)}
								/>
								<Controller
									name="password"
									control={control}
									rules={{
										required: 'Password is required',
									}}
									render={({ field: { onChange, value, name }, fieldState: { error } }) => (
										<PasswordInput
											onChange={onChange}
											value={value}
											name={name}
											error={error}
											label="Password"
											variant="outlined"
											fullWidth
										/>
									)}
								/>
							</Box>
						</CardContent>
						<CardActions sx={{ p: 2 }}>
							<Button size="large" variant="contained" type="submit" sx={{ width: '100%' }}>
								LOG IN
							</Button>
						</CardActions>
					</Card>
				</Paper>
			</Box>
		</form>
	);
}
