import { Box, Typography } from '@mui/material';
import packageInfo from '../../package.json';
const version = packageInfo.version;

function Footer() {
	return (
		<Box mt={8}>
			<Typography variant="subtitle2" color="zitara.grayOne" align="center">
				&copy; 2023 Zitara Technologies, All Rights Reserved.
			</Typography>
			<Typography variant="subtitle2" color="zitara.grayOne" align="center">
				v{version}
			</Typography>
		</Box>
	);
}

export { Footer };
