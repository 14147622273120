export const mainProtectedRoute = '/studio/data-sheet' as string;
export const mainPublicRoute = '/login' as string;

export const studioRoute = '/studio';
export const adminRoute = '/admin';

export const testDataRoute = '/studio/cell-data-download/test-data';
export const agingChartRoute = '/studio/cell-data-download/aging-chart';

// tammy 2024-01-09: this is not the correct gitbook URL! still testing some features with gitbook and will replace this after that
export const gitBookURL = 'https://zitara.gitbook.io/public-studio-documentation';
