import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import App from 'App';
import { AdminGuard } from 'AdminGuard';
import { AuthGuard } from 'AuthGuard';
import { Toolbar } from '@mui/material';
import { Box } from '@mui/material';
import { mainProtectedRoute, adminRoute, studioRoute } from 'routes/const';
import { isUrlValid } from 'routes/utils';

// error element
import ErrorCard from 'components/error-boundary/ErrorCard';

// login
import Login from 'pages/user-account/Login';
// activate account with token or reset password with token
import CreatePassword from 'pages/user-account/CreatePassword';
// user account settings
import AccountSettings from 'pages/user-account/AccountSettings';
import LaunchJob from 'pages/admin/dagster-job';
import { ArbinProcessJobsByTestPhrase } from 'pages/admin/dagster-job/ArbinProcessJobsByTestPhrase';

const CellDataTable = React.lazy(() => import('pages/cell-data-download/CellDataTable'));

// auth routes after this line
const TestProgramOverview = React.lazy(() => import('pages/test-program/TestProgramOverview'));
const TestProgram = React.lazy(() => import('pages/test-program/TestProgram'));
const BatteryLibrary = React.lazy(() => import('pages/battery-library/BatteryLibrary'));
const PackSimulation = React.lazy(() => import('pages/pack-simulation/PackSimulation'));
// const PackSimulation = React.lazy(() => import('pages/maintenance/Maintenance'));
const Simulations = React.lazy(() => import('pages/simulations/Simulations'));
const Simulation = React.lazy(() => import('pages/simulations/Simulation'));
const SimulationGroup = React.lazy(() => import('pages/simulations/SimulationGroup'));

const CellDataDownloadPage = React.lazy(() => import('pages/cell-data-download/CellDataDownloadPage'));
const DataSheet = React.lazy(() => import('pages/data-sheet/DataSheet'));

const AdminMain = React.lazy(() => import('pages/admin'));
const ModelPerformanceReview = React.lazy(() => import('pages/admin/model-performance-review/ModelPerformanceReview'));
const CellModelsTable = React.lazy(() => import('pages/admin/cell-model/CellModelsTable'));
const CellModel = React.lazy(() => import('pages/admin/cell-model/CellModel'));
const LabTestsTable = React.lazy(() => import('pages/admin/lab-test/LabTestsTable'));
const LabTest = React.lazy(() => import('pages/admin/lab-test/LabTest'));
const UsersTable = React.lazy(() => import('pages/admin/users/UsersTable'));
const UserDetail = React.lazy(() => import('pages/admin/users/UserDetail'));
const TestProgramsTable = React.lazy(() => import('pages/admin/test-programs/TestProgramsTable'));
const AdminTestProgram = React.lazy(() => import('pages/admin/test-programs/TestProgram'));
const AgingGroupsTable = React.lazy(() => import('pages/admin/aging-group/AgingGroupsTable'));
const AgingGroup = React.lazy(() => import('pages/admin/aging-group/aging-group'));
const AgingGroupsTemplatesTable = React.lazy(() => import('pages/admin/aging-group/AgingGroupTemplatesTable'));
const DeviceSpecTable = React.lazy(() => import('pages/admin/device-specifications'));

export const adminChildren = [
	{ path: 'model-performance-review', element: <ModelPerformanceReview /> },
	{
		path: 'aging-groups',
		children: [
			{ path: ':agingGroupId', element: <AgingGroup /> },
			{ path: '', element: <AgingGroupsTable /> },
		],
	},
	{ path: 'aging-group-templates', element: <AgingGroupsTemplatesTable /> },
	{
		path: 'cell-models',
		children: [
			{ path: ':cellModelId', element: <CellModel /> },
			{ path: '', element: <CellModelsTable /> },
		],
	},
	{
		path: 'lab-tests',
		children: [
			{ path: ':labTestId', element: <LabTest /> },
			{ path: '', element: <LabTestsTable /> },
		],
	},
	{
		path: 'users',
		children: [
			{ path: ':userId', element: <UserDetail /> },
			{ path: '', element: <UsersTable /> },
		],
	},
	{
		path: 'test-programs',
		children: [
			{ path: ':testProgramId', element: <AdminTestProgram /> },
			{ path: '', element: <TestProgramsTable /> },
		],
	},
	{
		path: 'device-specifications',
		children: [{ path: '', element: <DeviceSpecTable /> }],
	},
	{
		path: 'dagster-jobs',
		children: [
			{ path: 'arbin_cycler_and_thermal_by_test_phrase_job', element: <ArbinProcessJobsByTestPhrase /> },
			{ path: '', element: <LaunchJob /> },
		],
	},
	{ path: '', element: <AdminMain /> },
	{ path: '*', element: <Navigate to={adminRoute} /> },
];

const router = createBrowserRouter(
	[
		{
			path: '/login',
			element: (
				<Box component="main">
					<Toolbar />
					<Login />
				</Box>
			),
		},
		{
			path: '/activate',
			element: (
				<Box component="main">
					<Toolbar />
					<CreatePassword action="activateUser" />
				</Box>
			),
			loader: ({ request }) => isUrlValid(request.url, 'activationToken'),
			errorElement: <ErrorCard title="Activate Account" />,
		},
		{
			path: '/password',
			element: (
				<Box component="main">
					<Toolbar />
					<CreatePassword action="resetPassword" />
				</Box>
			),
			loader: ({ request }) => isUrlValid(request.url, 'passwordResetToken'),
			errorElement: <ErrorCard title="Reset Password" />,
		},
		{
			path: studioRoute,
			element: (
				<AuthGuard>
					<App />
				</AuthGuard>
			),
			children: [
				{
					path: 'test-programs',
					children: [
						{ path: ':testProgramId', element: <TestProgram /> },
						{ path: '', element: <TestProgramOverview /> },
					],
				},
				{
					path: 'battery-library',
					element: <BatteryLibrary />,
				},
				{
					path: 'data-sheet',
					element: <DataSheet />,
				},
				{
					path: 'simulation',
					element: <PackSimulation />,
				},
				{
					path: 'simulations',
					children: [
						{
							path: 'group',
							element: <SimulationGroup />,
						},
						{
							path: ':simId',
							element: <Simulation />,
						},
						{ path: '', element: <Simulations /> },
					],
				},
				{
					path: 'cell-data-download',
					element: <CellDataDownloadPage />,
					children: [
						{
							path: '',
							element: <Navigate to="test-data" replace />,
						},
						{
							path: 'test-data',
							element: <CellDataTable />,
						},
					],
				},
				{ path: 'account-settings', element: <AccountSettings /> },
			],
		},
		{
			path: adminRoute,
			element: (
				<AuthGuard>
					<AdminGuard>
						<App />
					</AdminGuard>
				</AuthGuard>
			),
			children: adminChildren,
		},
		{
			path: '*',
			element: (
				<AuthGuard>
					<Navigate to={mainProtectedRoute} />
				</AuthGuard>
			),
		},
	],
	{
		basename: process.env.REACT_APP_ROUTER_BASENAME,
	}
);

export default router;
