import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import getUserInfo from 'api/users/getUserInfo';
import { UserGroupBaseType } from 'api/api.types';

interface CurrentUserGroupType extends UserGroupBaseType {
	isZitaraAdminUserGroup: boolean;
}

export interface UserInfoType {
	id: string;
	externalId: string;
	customer: string;
	email: string;
	fullName: string;
	isZitaraAdmin: boolean;
	currentUserGroup: CurrentUserGroupType;
	assumableUserGroups: UserGroupBaseType[];
	gitbookToken: string;
}

interface UserState {
	user: UserInfoType | null;
	accessToken: string;
	isAuthenticated: boolean;
	gitBookToken: string;
}

interface UserStore extends UserState {
	addUser: (data: UserState) => void;
	addUserState: (token: string) => Promise<void>;
	clearUserState: () => void;
}

const defaultUserState = { user: null, accessToken: '', isAuthenticated: false } as UserState;

export const useUserStore = create<UserStore>()(
	devtools(
		persist(
			(set, get) => ({
				...defaultUserState,
				addUser: (data: UserState) => set(data),
				addUserState: async (token: string) => {
					try {
						const user = await getUserInfo(token);
						get().addUser({
							accessToken: token,
							user: user.userInfo,
							isAuthenticated: true,
							gitBookToken: user.userInfo.gitbookToken,
						});
					} catch (err) {
						get().clearUserState();
					}
				},
				clearUserState: () => {
					set({ user: null, isAuthenticated: false, accessToken: '' });
				},
			}),
			{
				name: 'user-storage',
			}
		)
	)
);
